@import '../../variables';

.FullService {
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: start;
    border-radius: $border_radius;
    padding: 30px;
    padding-top: 40px;
    position: relative;
    max-width: $page_max_width;
    opacity: 0;
    animation: fadeIn 0.5s ease-out forwards;

    .Icon {
        height: 26px;
        margin-bottom: 40px;
    }

    .Text {
        max-width: 500px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .BookWithIt {
        margin-top: 30px;
        padding: 10px 15px;
        background-color: $theme_color;
        border-radius: 20px;
        align-self: end;
        color: white;

        @include enable_hover() {
            &:hover {
                background-color: $hover_theme_color;
            }
        }
    }

    .close {
        position: absolute;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: $grey_button;
        background-image: url("../../../public/images/close.svg");
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 10px;
        right: 15px;
        top: 15px;

        @include enable_hover() {
            &:hover {
                background-color: $hover_grey_button_color;
            }
        }
    }
}

.ToggleAdd {
    padding: 10px 15px;
    margin-top: 20px;
    border-radius: 20px;
    margin-left: auto;
    &.Add {
        background-color: $theme_color;
        color: white;

        @include enable_hover() {
            &:hover {
                background-color: $hover_theme_color;
            }
        }
    }

    &.Delete {
        background-color: $grey_button;

        @include enable_hover() {
            &:hover {
                background-color: $hover_grey_button_color;
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}