@import '../../variables';

.Apart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border-radius: $border_radius;
    transition: transform 0.3s ease-out;
    cursor: pointer;
    @include tablet() {
        cursor: default;
    }

    @include enable_hover(){
        &:hover{
            transform: scale(1.03);
        }
    }

    .ImagesBlock {
        height: 274.5px;
        overflow-x: auto;
        overflow-y: hidden;
        border-radius: $border_radius;

        &::-webkit-scrollbar {
            height: 0px;
        }

        scroll-snap-type: x mandatory;

        .SliderItem {
            height: 100%;
            display: flex;
            width: calc(1464px + 366px / 3 * 2);


            .ImageWrapper {
                scroll-snap-align: start;
                width: 366px;
                overflow: hidden;

                img {
                    height: 105%;
                }
            }

            &.pos4 {
                .MorePhotos {
                    img {
                        // transform: translateX(-15%);
                    }

                    a {
                        animation: bounce 0.8s ease-in-out;
                    }
                }
            }

            @keyframes bounce {
                0% {
                    transform: translateX(0);
                }

                25% {
                    transform: translateX(-20px);
                }

                50% {
                    transform: translateX(10px);
                }

                75% {
                    transform: translateX(-5px);
                }

                100% {
                    transform: translateX(0);
                }
            }

            .MorePhotos {
                scroll-snap-align: end;
                width: calc(366px / 3 * 2);
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                position: relative;
                height: 100%;
                background: rgb(0, 0, 0);

                img {
                    transition: transform 1s ease-out;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    filter: opacity(0.4) blur(8px);
                    top: 0;
                }

                a {
                    cursor: pointer;
                    padding: 10px 15px;
                    color: #eee;
                    border-radius: 20px;
                    z-index: 1;
                    padding-right: 14px;
                    background-image: url('../../../public/images/arrow_white.svg');
                    background-size: 6px;
                    background-position-y: calc(50% + 2px);
                    background-position-x: calc(100% + 0px);
                    background-repeat: no-repeat;
                    @include enable_hover() {
                        &:hover {
                            opacity: 0.9;
                        }
                    }

                }

            }
        }

        @include mobile {
            height: calc($page_max_width*3/4);

            .SliderItem {
                width: calc(($page_max_width * 4) + $page_max_width / 3 * 2);

                .ImageWrapper {
                    width: $page_max_width;
                }

                .MorePhotos {
                    width: calc($page_max_width / 3 * 2);
                }
            }
        }
    }

    .Content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .ImageButtons {
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;

            button {
                height: 30px;
                width: 30px;
                background-color: $grey_button;
                background-image: url("../../../public/images/arrow_black.svg");
                background-repeat: no-repeat;
                background-position: 50%;
                background-size: 50%;
                border-radius: 50%;
                background-position-y: calc(50% + 1px);

                &.left {
                    transform: rotate(90deg);
                }

                &.right {
                    transform: rotate(-90deg);
                }

                @include enable_hover() {
                    &:hover {
                        background-color: $hover_grey_button_color;
                        &.hide{
                            background-color: $grey_button;
                        }
                    }
                }

                &.hide {
                    opacity: 0.5;
                    // pointer-events: none;
                }
            }
        }

        .Title {
            margin-top: 25px;
        }

        .Description {
            margin-top: 25px;
        }

        .Price {
            .Numbers{
                font-size: 20px;
            }
            margin-top: 5px;

        }

        .Buttons {
            margin-top: 25px;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;

            .Book {
                color: $theme_color;
                padding-right: 14px;
                background-image: url('../../../public/images/arrow_theme.svg');
                background-size: 6px;
                background-position-y: calc(50% + 2px);
                background-position-x: calc(100% + 0px);
                background-repeat: no-repeat;

                @include enable_hover() {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .More {
                background-color: $theme_color;
                color: white;
                padding: 10px 15px;
                border-radius: 20px;

                @include enable_hover() {
                    &:hover {
                        background-color: $hover_theme_color;
                    }
                }
            }
        }
    }
}