@import '../../variables';

.Header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $black_color !important;
    // background-color: $second_color;
    background-color: white;

    .HeaderInner {
        width: $page_width;
        max-width: $page_max_width;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            height: 25px;
            margin-right: 40px;
        }

        .menu ul {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;
        }

        .contants {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;

        }
        .tg {
            height: 20px;
            width: 20px;
            background-image: url('../../../public/images/tg.svg');
            background-size: 100%;
            background-position: 50%;
            background-repeat: no-repeat;
        }

        .OpenMobile {
            width: 20px;
            display: none;

            img {
                width: 100%;
            }
            margin-left: 97px;
            @include tablet(){
                margin-left: 0;
            }
        }

        @media screen and (max-width:1050px) {
            .OpenMobile {
                display: block;
            }

            .logo {
                margin-right: 0;
            }

            .menu {
                display: none;
            }

            .contants {
                .tg {
                    display: none;
                }
            }
        }

    }

    &:not(.Home) {
        .HomePhone {
            display: none;
        }
    }
    .tgHomeTablet{
        display: none;
    }
    .Phone{
        pointer-events: none;
        @include tablet(){
            pointer-events: all;
        }
    }
    &.Home {
        .HomePhone {
            color: black;
            display: none;
        }
        @include tablet(){
            .Logo{
                display: none;
            }
            .HomePhone {
                display: none;
            }
            .Phone{
                display: block;
            }
            .tgHomeTablet{
                display: block;
            }
        }
    }
}

.MobileMenu {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: white;
    padding: $page_mobile_padding;
    z-index: 60;

    .Close {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: $grey_button;
        background-image: url("../../../public/images/close.svg");
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 10px;
        margin-bottom: 30px;
        margin-left: auto;
        display: block;

        @include enable_hover() {
            &:hover {
                background-color: $hover_grey_button_color;
            }
        }
    }

    .menu {
        ul {
            display: flex;
            flex-direction: column;
            gap: 20px;

            li {
                padding: 10px 15px;
                background-color: $second_color;
                border-radius: $border_radius;
            }

            .contacts {
                background-color: transparent;
                display: flex;
                gap: 15px;
                padding: 0;
                width: 100%;
                justify-content: end;

                .image {
                    height: 40px;
                    width: 40px;
                    background-position: 50%;
                    background-size: 50%;
                    padding-left: 20px;
                    background-repeat: no-repeat;
                    border-radius: 50%;

                    &.number {
                        background-color: #44C554;
                        background-image: url("../../../public/images/phone_white.svg");
                    }

                    &.tg {
                        background-position: (45%, 48%);
                        background-color: #29ABE2;
                        background-image: url("../../../public/images/tg_white.svg");
                    }
                }
            }
        }
    }
}