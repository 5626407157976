@import '../../variables';

.WelcomeBlock {
    min-height: 500px;
    max-height: 1000px;
    height: calc(100vh - 50px);
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $second_color;

    .Background {
        position: absolute;
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: $page_end;

        @include tablet {
            display: none;
        }
    }

    .BackgroundMobile {
        position: absolute;
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: $page_end;
        display: none;

        @include tablet {
            transform: translate(-50%, -50%) scale(1.5);
            display: block;
        }

        @include small_mobile {
            transform: translate(-50%, -50%) scale(1.1);
            height: 100%;
            width: auto;
        }

    }

    .preHeader {
        height: 80px;
        width: 100%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        z-index: 2;

        .ButtonBook {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            background-color: $theme_color;
            background-image: url("../../../public/images/calendar_white.svg");
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 35%;

            @include enable_hover() {
                &:hover {
                    background-color: $hover_theme_color;
                }
            }
        }

        @include mobile() {
            .ButtonBook {
                position: absolute;
                right: calc(50vw - 160px);
                height: 30px;
                width: 30px;
            }

            .ButtonAparts {
                margin-left: 0;
            }
        }
    }

    .Content {
        width: $page_width;
        max-width: $page_max_width;
        color: white;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        max-width: $page_end;
        max-width: $page_max_width;
        z-index: 1;
        flex: 2;
        height: calc(100%);
        .LogoText{
            height: 50px;
            position: absolute;
            left:50%;
            transform: translateX(-50%);
            top: 30px;
            display: none;
            @include tablet(){
                display: block;
            }
        }
        .Text {
            display: flex;
            flex-direction: column;
            text-align: start;
            max-width: 500px;


            .ButtonsMain {
                margin-top: 40px;
                display: flex;
                gap: 20px;
                align-items: center;
                justify-content: start;

                .ButtonAparts {
                    padding: 10px 20px;
                    background-color: $theme_color;
                    color: white;
                    border-radius: 20px;
                    // margin-left: 70px;
                    background-color: white;
                    color: black;

                    background-color: $theme_color;
                    color: white;
                    @include enable_hover() {
                        &:hover {
                            background-color: rgb(235, 235, 235);
                            background-color: $hover_theme_color;
                            // background-image: url("../../../public/images/arrow_black_text.svg");
                        }
                    }
                }

                .Services {
                    color: white;
                    margin-right: auto;
                    padding-right: 14px;
                    background-image: url('../../../public/images/arrow_white.svg');
                    background-size: 6px;
                    background-position-y: calc(50% + 2px);
                    background-position-x: calc(100% + 0px);
                    background-repeat: no-repeat;
                    @include enable_hover() {
                        &:hover {
                            text-decoration: underline;
                            // background-image: url("../../../public/images/arrow_black_text.svg");
                        }
                    }
                }
                @include tablet(){
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .Services{
                        margin-right: 0px;
                    }
                }
            }
        }

        .BookMainWrapper {
            text-align: start;
            color: black;
            width: 350px;
            max-height: calc(100% - 50px);

            @include tablet() {
                max-height: 100000px;
            }

            overflow-y: auto;
            border-radius: $border_radius;

            &::-webkit-scrollbar {
                width: 0px;
            }
        }

        .Big {
            transition: opacity 0.5s ease-out;
            max-width: $page_max_width;
        }

        .mainText {
            width: 450px;
            max-width: $page_max_width;
            margin-top: 15px;
            transition: opacity 0.5s ease-out;
        }

        .MainBook {
            padding: 10px 15px;
            border-radius: 20px;
            color: black;
            background-color: white;
            color: black;
            margin-top: 45px;
            // background-image: url("../../../public/images/arrow_white.svg");
            background-repeat: no-repeat;
            background-position-x: calc(100% - 15px);
            background-position-y: calc(50% + 2px);
            // padding-right: 30px;
            background-size: 7px;

            @include enable_hover() {
                &:hover {
                    background-color: rgb(235, 235, 235);
                    // background-image: url("../../../public/images/arrow_black_text.svg");
                }
            }
        }

        .ButtonMore {
            margin-top: 45px;
            height: 40px;
            width: 40px;
            background-color: #F5F5F7;
            background-image: url("../../../public/images/arrow_black.svg");
            background-position: 50%;
            background-position-y: 55%;
            background-repeat: no-repeat;
            background-size: 50%;
            border-radius: 50%;
            transition: opacity 0.5s ease-out;
        }

        .hide {
            opacity: 0;
        }

        &::after {
            content: "";
            height: calc(100%);
            width: 100%;
            position: absolute;
            display: block;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            background: rgba(0, 0, 0, 0.6);
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.8) 100%);
            max-width: $page_end;
            z-index: -1;
        }

        // &::before {
        //     content: "";
        //     height: 15px;
        //     width: 100%;
        //     position: absolute;
        //     display: block;
        //     left: 50%;
        //     bottom: 0;
        //     transform: translateX(-50%);
        //     background: white;
        //     max-width: $page_end;
        //     z-index: 1;
        // }

        @include tablet() {
            flex-direction: column;

            .Text {
                margin: 150px 0 150px 0;
                align-items: center;
                text-align: center;

                .ButtonAparts {
                    margin-right: 0;
                }
            }

            .BookMainWrapper {
                margin-bottom: 100px;
            }
        }
    }

    @include tablet() {
        max-height: 100000px;
        height: auto !important;
    }
}

.BlockTitle {
    display: block;
    margin-bottom: 30px;
}

.animation {
    transition: transform 0.7s ease-out, opacity 0.7s ease-out;

    &:not(.animation_show) {
        transform: translateY(50px);
        opacity: 0;
    }
}

.ServiceBlock {
    display: flex;
    justify-content: center;
    background-color: white;
    padding: 100px 0;

    .Content {
        width: $page_width;
        max-width: $page_max_width;

        .TopBlock {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .GoAparts {
                color: $theme_color;
                font-size: 17px;
                display: block;
                padding-right: 14px;
                background-image: url('../../../public/images/arrow_theme.svg');
                background-size: 6px;
                background-position-y: calc(50% + 2px);
                background-position-x: calc(100% + 0px);
                background-repeat: no-repeat;

                @include enable_hover() {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            @include mobile {
                flex-direction: column;
                align-items: start;

                .GoAparts {
                    align-self: end;
                    margin-bottom: 15px;
                }
            }
        }

        .Services {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 20px;

            .Service {
                position: relative;
                background-color: $second_color;
                border-radius: $border_radius;
                padding: 30px;
                padding-bottom: 230px;

                overflow: hidden;

                .Background {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: 200px;
                }

                &.Sert {
                    .Background {
                        transform: translate(-25%, 30%);
                        height: 300px;
                    }
                }

                .Icon {
                    height: 26px;
                }

                .Bold {
                    margin-top: 40px;
                }

                .Thin {
                    margin-top: 15px;
                }

                .Expand {
                    position: absolute;
                    display: block;
                    height: 40px;
                    width: 40px;
                    right: 30px;
                    bottom: 30px;
                    margin-top: auto;
                    background-color: $theme_color;
                    border-radius: 50%;
                    background-image: url("../../../public/images/arrow_white.svg");
                    background-position: 50%;
                    background-size: 23%;
                    background-repeat: no-repeat;

                    @include enable_hover() {
                        &:hover {
                            background-color: $hover_theme_color;
                        }
                    }
                }
            }
        }

        .ServiceNavs {
            margin-top: 50px;

            ul {
                display: flex;
                flex-direction: column;
                align-items: start;
                gap: 20px;

                .Nav {
                    a {
                        display: flex;
                        align-items: center;

                        .Icon {
                            margin-right: 10px;
                            height: 20px;
                        }

                        .Arrow {
                            height: 8px;
                            transform: rotate(-90deg) translateX(-2px);
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}

.ApartsBlock {
    display: flex;
    justify-content: center;
    padding: 100px 0 100px 0;
    background-color: $second_color;

    &.Pending {
        height: 500px;
    }

    .TopBlock {
        .Info {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: start;

            img {
                height: 26px;
                margin-bottom: 15px;
            }

            span {
                max-width: 700px;
                margin-bottom: 40px;
            }
        }

        @include laptop {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            .Info {
                align-items: center;
            }
        }
    }

    .LoadingBlock {
        width: $page_width;
        max-width: $page_max_width;

        @include tablet {
            display: flex;
            justify-content: center;
        }

        img {
            height: 50px;
            animation: rotate 2s linear infinite;
            /* Анимация вращения */
        }
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
            /* Начальный угол */
        }

        to {
            transform: rotate(360deg);
            /* Конечный угол */
        }
    }

    .Content {
        width: $page_width;
        max-width: $page_max_width;
        display: flex;
        flex-direction: column;

        .Aparts {
            display: grid;
            grid-template-columns: repeat(auto-fit, 366px);

            @include mobile {
                grid-template-columns: repeat(auto-fit, $page_max_width);
            }

            justify-content: center;
            gap: 50px;

            .CardWrapper {
                @include laptop {
                    transition-delay: 0s !important;
                }
            }
        }
    }
}

.InfrascructureBlock {
    padding: 100px 0;
    overflow: hidden;

    &.Pending {
        height: 500px;
    }


    .LoadingBlock {
        width: $page_width;
        max-width: $page_max_width;
        margin: 0 auto;
        margin-top: 50px;

        @include tablet {
            display: flex;
            justify-content: center;
        }

        img {
            height: 50px;
            animation: rotate 2s linear infinite;
            /* Анимация вращения */
        }
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
            /* Начальный угол */
        }

        to {
            transform: rotate(360deg);
            /* Конечный угол */
        }
    }

    .TopBlock {
        width: $page_width;
        max-width: $page_max_width;
        display: flex;
        margin: 0 auto;
        flex-direction: column;

        @include tablet() {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }


    }

    .InfraButtons {
        display: flex;
        gap: 20px;

        button {
            background-color: $second_color;
            padding: 10px 15px;
            border-radius: 25px;
            background-position: 14px;
            background-repeat: no-repeat;
            background-size: 14px;
            padding-left: 40px;

            @include enable_hover() {
                &:hover {
                    background-color: #dedede !important;
                }
            }

            &.infra_active {
                color: white;
                pointer-events: none;
                background-color: $theme_color;
            }
        }
    }

    .rest {
        background-image: url("../../../public/images/rest_black.svg");

        &.infra_active {
            background-image: url("../../../public/images/rest_white.svg");
        }
    }

    .bars {
        background-image: url("../../../public/images/bars_black.svg");

        &.infra_active {
            background-image: url("../../../public/images/bars_white.svg");
        }
    }

    .spa {
        background-image: url("../../../public/images/spa_black.svg");

        &.infra_active {
            background-image: url("../../../public/images/spa_white.svg");
        }
    }

    .Content {
        margin-top: 50px;
        width: calc(100vw);
        overflow-x: auto;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;

        &::-webkit-scrollbar {
            height: 0px;
        }

        .Infras {

            .SlideContent {
                display: flex;
                gap: 50px;

                .WrapperInfra {
                    scroll-snap-align: center;
                }

                .WrapperInfra:first-child {
                    padding-left: calc((100vw - $page_width) / 2);

                    @include laptop {
                        padding-left: $page_mobile_padding;
                    }
                }

                .WrapperInfra:last-child {
                    padding-right: calc((100vw - $page_width) / 2);

                    @include laptop {
                        padding-right: $page_mobile_padding;
                    }
                }
            }
        }

    }

    .ButtonsBlock {
        display: flex;
        gap: 15px;
        margin: 0 auto;
        margin-top: 20px;
        justify-content: end;
        width: $page_width;
        max-width: $page_max_width;

        button {
            height: 30px;
            width: 30px;
            background-color: $grey_button;
            background-image: url("../../../public/images/arrow_black.svg");
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 50%;
            border-radius: 50%;
            background-position-y: calc(50% + 1px);

            &.LeftButton {
                transform: rotate(90deg);
            }

            &.RightButton {
                transform: rotate(-90deg);
            }

            @include enable_hover() {
                &:hover {
                    background-color: $hover_grey_button_color;
                }
            }

            &.hide {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
}

.ReveiwsBlock {
    padding: 100px 0 100px 0;
    background-color: $second_color;
    display: flex;
    flex-direction: column;
    align-items: center;

    .TopBlock {
        width: $page_width;
        max-width: $page_max_width;
        // padding-left: calc((100vw - $page_width) / 2);

        .Info {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: start;

            img {
                height: 26px;
            }

            span {
                max-width: 700px;
                margin-top: 15px;
                margin-bottom: 40px;
            }
        }
    }

    .Content {}
}

.ContantsBlock {
    padding: 100px 0 100px 0;
    display: flex;
    justify-content: center;
    background-color: $second_color;

    .Content {
        width: $page_width;
        max-width: $page_max_width;

        .Blocks {
            display: grid;
            grid-template-columns: 386px 1fr;
            align-items: stretch; // Второй блок растягивается по высоте первого
            gap: 20px;
            width: 100%;

            @include mobile() {

                grid-template-columns: 1fr;
                grid-template-rows: auto auto;

                .Rules {
                    .Top {
                        flex-direction: column !important;
                        gap: 0 !important;

                        .Right {
                            margin-top: 0 !important;
                        }
                    }
                }
            }

            .Block {
                background-color: white;
                padding: 30px;
                border-radius: $border_radius;
                display: flex;
                align-items: start;
                flex-direction: column;
                justify-content: space-between;

                .Top {
                    display: flex;
                    flex-direction: column;
                    align-items: start;

                    img {
                        height: 26px;
                    }

                    .Middle {
                        margin-top: 40px;
                    }

                    .Text {
                        margin-top: 15px;
                    }
                }
            }

            .Contacts {
                .Top {
                    .ContactsList {
                        display: flex;
                        flex-direction: column;
                        gap: 15px;

                        li {
                            background-repeat: no-repeat !important;
                            background-size: 17px;
                            background-position: 0px;
                            padding-left: 23px;
                        }

                        .phone {
                            background-image: url("../../../public/images/phone_black.svg");
                        }

                        .tg {
                            background-image: url("../../../public/images/tg_black.svg");
                        }

                        .mail {
                            background-image: url("../../../public/images/mail_black.svg");
                        }
                    }
                }

                .bottom {
                    width: 100%;
                    display: flex;
                    justify-content: end;
                    gap: 10px;

                    img {
                        height: 35px;
                        left: 0;
                    }
                }
            }

            .Rules {
                .Top {
                    display: flex;
                    gap: 50px;
                    flex-direction: row;

                    .Left {
                        display: flex;
                        flex-direction: column;
                        align-items: start;
                    }

                    .Right {
                        display: flex;
                        flex-direction: column;
                        align-items: start;
                        margin-top: 26px;

                        .Text {}
                    }
                }

                .bottom {
                    display: flex;
                    justify-content: end;
                    width: 100%;

                    a {
                        display: block;
                        margin-top: 30px;
                        background-color: $theme_color;
                        padding: 10px 15px;
                        border-radius: 20px;
                        color: white;

                        @include enable_hover() {
                            &:hover {
                                background-color: $hover_theme_color;
                            }
                        }
                    }
                }
            }
        }
    }
}

.WrapperFullService {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #cdcdcd77;
    backdrop-filter: blur(10px);
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: fadeIn 0.3s ease-out forwards;

    .Inner {
        width: 350px;
        max-width: $page_max_width;
    }
}