.react-datepicker {
    border: none !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker-wrapper input {
    width: 100%;
    background-image: url('../../../public/images/calendar.svg');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: 50%;
    cursor: pointer;
    padding: 10px;
    border-radius: 15px;
    font-size: 13px;
    background-color: rgba(255, 255, 255, 0.1) !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.react-datepicker-wrapper input:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
    background-color: rgba(0, 0, 0, 0.2) !important;
}

.react-datepicker-wrapper input::placeholder {
    font-size: 13px;
    color: white;
    opacity: 0.7;
}

.react-datepicker__input-container {
    width: 100%;
    border-radius: 15px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    display: none;
}

.react-datepicker__header {
    background-color: white;
}

.react-datepicker__close-icon {
    width: 28px;
    display: none;
}


.react-datepicker__day--disabled {
    color: #ccc;
}

.react-datepicker__week {
    margin: 10px 0px;
}

.react-datepicker__day--keyboard-selected {
    background-color: rgba(0, 0, 255, 0.451);
    border-top-left-radius: 50%;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: transparent;
}

.react-datepicker__day--in-range {
    background-color: rgba(0, 0, 255, 0.451);
    color: white !important;
}

.react-datepicker__day--in-range:hover {
    background-color: rgba(0, 94, 255, 0.426) !important;
    color: white;
}

.react-datepicker__navigation--previous {
    left: auto;
    right: 30px;
}
.react-datepicker__navigation{
    top: 12px;
}
h2.react-datepicker__current-month {
    text-align: left;
    margin-left: 9px;
    padding: 10px;
    color: #AA895C;
}

.react-datepicker__day-name {
    margin: 0;
    width: 40px;
}

.react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: rgba(0, 94, 255, 0.426) !important;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "×";
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: rgba(0, 0, 255, 0.451);
    color: white !important;
}

.react-datepicker__day--keyboard-selected:not([aria-disabled=true]):hover {
    background-color: rgba(0, 0, 255, 0.451);
}

.react-datepicker__day--in-selecting-range:not([aria-disabled=true]):hover {
    background-color: rgba(0, 0, 255, 0.451);
}

.PhoneInput input {
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(0, 0, 0, 0.05);
    font-size: 13px;
    border-radius: 15px;
}

.PhoneInput input::placeholder {
    font-size: 13px;
    color: #fff;
    opacity: 0.7;
}

.PhoneInputCountry {
    display: none !important;
}

.PhoneInput input:-webkit-autofill {
    background-color: rgba(255, 255, 255, 0.05) !important;
    /* Убираем фоновый цвет */
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    color: #fff !important;
    /* Сохраняем плавный переход фона */
}

/* Для Firefox */
.PhoneInput input:-moz-autofill {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: #fff !important;
    background-color: rgba(0, 0, 0, 0.05) !important;
    color: #000 !important;
    /* Убираем фоновый цвет */
}
.react-datepicker__day {
    width: 40px;
    margin: 0 !important;
    border-radius: 0px !important;
}