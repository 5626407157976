@import '../../variables';

$mobile_gallery_width: 100vw;
$PC_gallery_height: calc(100vh - 100px);
$PC_gallery_width: calc($PC_gallery_height * 4 / 3);

@mixin Mobile_Gallery {
    @media screen and (max-width: calc(calc(100vh - 100px) * 4 / 3)) {
        @content;
    }
}

.Gallery {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000000000;
    background-color: rgba(0, 0, 0, 0.4666666667);
    backdrop-filter: blur(10px);
    .WrapperImagesBlock {
        position: relative;
    }

    .ImagesBlock {
        &::-webkit-scrollbar {
            height: 0;
            width: 0;
        }

        border-radius: $border_radius;
        background-color: rgb(69, 69, 69);

        @include small_mobile() {
        }

        height: $PC_gallery_height;
        width: $PC_gallery_width;

        @include small_mobile() {
            height: auto !important;
        }

        @include Mobile_Gallery() {
            width: 100vw !important;
            border-radius: 0;
            max-height: calc(($PC_gallery_height * 4 / 3) - 400px);
        }

        overflow-x: auto;
        position: relative;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;


        .ScrollItem {
            height: 100%;
            @include small_mobile(){
                max-height: calc(($PC_gallery_height * 4 / 3) - 400px);
            }
            display: flex;
            align-items: center;
            justify-content: center;
            .ImageWrapper {
                scroll-snap-align: start;
                height: 100%;


                width: $PC_gallery_width;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: 100%;
                }

                @include small_mobile() {
                    height: 100%;
                    width: 100%;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}

.buttonSwipe {
    position: absolute;
    height: 100%;
    width: 50px;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(2px);
    top: 0;
    background-image: url("../../../public/images/arrow_white.svg");
    background-size: 10px;
    background-position: 50%;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-out;
    border-top-right-radius: $border_radius;
    border-bottom-right-radius: $border_radius;

    &.hide {
        opacity: 0;
        pointer-events: none;
    }

    @include enable_hover() {
        &:hover {
            background-color: rgba(220, 220, 220, 0.4);
        }
    }

    &.leftSwipe {
        left: 0;
        transform: rotate(180deg);
    }

    &.rightSwipe {
        right: 0;
    }

    @include Mobile_Gallery() {
        border-radius: 0;
    }
    @include tablet() {
        border-radius: 50%;
        height: 40px;
        width: 40px;
        top: 50%;
        &.leftSwipe {
            left: 20px;
        }
    
        &.rightSwipe {
            right: 20px;
        }
    }

}

.Close {
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: $grey_button;
    background-image: url("../../../public/images/close.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 10px;
    right: -50px;
    top: 0px;

    @include enable_hover() {
        &:hover {
            background-color: $hover_grey_button_color;
        }
    }
    
    @include Mobile_Gallery() {
        right: 20px;
        top: -40px;
    }
}