$black_color: #333333;
$second_color: #F5F5F7;
$page_width: 1200px;
$page_mobile_padding: 25px;
$page_max_width: calc(100vw - ($page_mobile_padding * 2));
$page_end: 3000px;
$font_Big_PC: 40px;
$font_Big_Mobile: 26px;
$font_Middle_PC: 25px;
$font_Middle_Mobile: 20px;
$font_Small_PC: 15px;
$font_Small_Mobile: 13px;
$theme_color: #AA895C;
$border_radius: 30px;
$hover_theme_color: #9d7d51;
$grey_button: #E6E6E6;
$grey_button2: #DEDEE2;
$hover_grey_button_color: #CFCFCF;

@mixin small_mobile {
    @media screen and (max-width:600px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width:792px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width:850px) {
        @content;
    }
}

@mixin laptop {
    @media screen and (max-width:1260px) {
        @content;
    }
}

@mixin enable_hover {
    @media (hover: hover) {
        @content;
    }
}

.Regular {
    font-weight: 400 !important;
}

.Thin {
    font-weight: 300;
}

.Bold {
    font-weight: 500;
}

.Hide {
    color: #757575;
}

.Big {
    font-size: $font_Big_PC;

    @include tablet() {
        font-size: $font_Big_Mobile;
    }
}

.Middle {
    font-size: $font_Middle_PC;

    @include tablet() {
        font-size: $font_Middle_Mobile;
    }
}

.Small {
    font-size: $font_Small_PC;

    @include tablet() {
        font-size: $font_Small_Mobile;
    }
}

.discount{
    font-weight: 300;
    text-decoration: line-through;
    font-size: 13px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}