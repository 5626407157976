@import '../../variables';

.AddServices {
    border-radius: $border_radius;
    background-color: white;
    padding: 30px;
    padding-top: 50px;
    overflow: auto;
    max-width: $page_max_width;
    position: relative;
    transition: opacity 0.3s ease-out;
    max-height: calc(100vh - 50px);

    @include tablet() {
        max-height: calc(100vh - 150px);
    }

    &::-webkit-scrollbar {
        width: 0px;
    }

    &.HideThis {
        opacity: 0;
    }

    .close {
        position: absolute;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: $grey_button;
        background-image: url("../../../public/images/close.svg");
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 10px;
        right: 15px;
        top: 15px;

        @include enable_hover() {
            &:hover {
                background-color: $hover_grey_button_color;
            }
        }
    }

    display: flex;
    gap: 40px;

    .Service {
        background-color: $second_color;
        border-radius: $border_radius;
        padding: 30px;
        width: 300px;
        display: flex;
        flex-direction: column;
        max-width: calc(100vw - 2 * $page_mobile_padding - 2 * 20px);

        .Middle {
            margin-bottom: 10px;
        }

        .Text {
            margin-bottom: 15px;
        }

        .Buttons {
            margin-top: auto;
            align-self: end;
            display: flex;
            gap: 20px;
            justify-content: space-between;
            width: 100%;

            .Add {
                height: 25px;
                width: 25px;
                background-color: $theme_color;
                border-radius: 50%;
                background-image: url("../../../public/images/plus_white.svg");
                background-size: 10px;
                background-position: 50%;
                background-repeat: no-repeat;

                @include enable_hover() {
                    &:hover {
                        background-color: $hover_theme_color
                    }
                }

            }

            .Delete {
                height: 25px;
                width: 25px;
                background-color: $grey_button;
                border-radius: 50%;
                background-image: url("../../../public/images/minus_black.svg");
                background-size: 10px;
                background-position: 50%;
                background-repeat: no-repeat;

                @include enable_hover() {
                    &:hover {
                        background-color: $hover_grey_button_color
                    }
                }

            }

            .More {
                padding-right: 14px;
                background-image: url("../../../public/images/arrow_theme.svg");
                color: $theme_color;
                background-size: 6px;
                background-position-y: calc(50% + 1px);
                background-position-x: calc(100% + 0px);
                background-repeat: no-repeat;

                @include enable_hover() {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    @include tablet() {
        flex-direction: column;
        padding: 50px 20px 30px 20px;
        gap: 20px;
    }
}

.WrapperFullService {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: fadeIn 0.3s ease-out forwards;

    .Inner {
        width: 350px;
        max-width: $page_max_width;
    }
}