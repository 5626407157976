@import '../../variables';


.Footer {
    padding: 100px 0 50px 0;
    display: flex;
    justify-content: center;

    font-size: 15px !important;
    @include laptop(){
        padding-bottom: $page_mobile_padding;
    }
    .Content {
        width: $page_width;
        max-width: $page_max_width;

        .Top {
            display: flex;
            gap: 50px;
            align-items: end;
            @include mobile(){
                flex-wrap: wrap;
                :nth-child(1){
                    flex-basis: 100% !important;
                }
            }
            .Block {
                flex: 1 1 auto; /* Все элементы растягиваются */
                &:first-child {
                    flex: 2;
                }

                display: flex;
                flex-direction: column;
                gap: 15px;

                .Title {
                    font-weight: bold;
                    opacity: 0.8;
                }

                .Field {
                    opacity: 0.6;

                    img {
                        height: 15px;
                        margin-right: 5px;
                    }
                }
            }
        }

        .Bottom {
            padding-top: 15px;
            margin-top: 15px;
            border-top: 1px solid black;
            opacity: 0.6;
            display: flex;
            justify-content: space-between;

            @include laptop {
                display: none;
            }
        }

        .BottomMobile {
            padding-top: 15px;
            margin-top: 15px;
            border-top: 1px solid black;
            opacity: 0.6;
            justify-content: space-between;
            display: none;
            flex-direction: column;
            gap: 10px;

            .One {
                display: flex;
                justify-content: space-between;
            }

            .Infos {
                display: flex;
                gap: 10px;
                @include mobile(){
                    flex-direction: column;
                    margin-top: 10px;
                    span{
                        display: none;
                    }
                }
            }

            @include laptop {
                display: flex;
            }
        }
    }
}