@import '../../variables';
$input_background: rgba(0, 0, 0, 0.05);
$input_border_radius: 15px;

.Book {
    width: 100%;
    border-radius: $border_radius;
    background-color: $second_color;
    padding: 30px;
    height: 100%;
    position: relative;
    max-height: calc(100vh - 50px);

    @include tablet() {
        max-height: calc(100vh - 150px);
    }

    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 0px;
    }


    &.Custom {
        background-color: white;
        padding-top: 40px;
    }

    transition: opacity 0.3s ease-out;

    &.HideThis {
        opacity: 0;
    }

    .close {
        position: absolute;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: $grey_button;
        background-image: url("../../../public/images/close.svg");
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 10px;
        right: 15px;
        top: 15px;

        @include enable_hover() {
            &:hover {
                background-color: $hover_grey_button_color;
            }
        }
    }
}

.order {
    height: 100%;
    display: flex;
    flex-direction: column;

    .Title {
        margin-bottom: 20px;
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .InputField {
            display: flex;
            flex-direction: column;

            .bookTitle {
                font-weight: 400 !important;
                font-size: 14px;
                margin-bottom: 10px;
            }

            input {
                border: 1px solid transparent;
                font-size: 13px !important;
                padding: 10px;
                background-color: $input_background;
                border-radius: $input_border_radius;
            }
        }

        .date {
            .calendarButton {
                border: 1px solid transparent;
                padding: 10px;
                background-color: $input_background;
                width: 100%;
                border-radius: $input_border_radius;
                text-align: start;
                background-image: url('../../../public/images/calendar.svg');
                background-repeat: no-repeat;
                background-position-x: calc(100% - 10px);
                background-position-y: 50%;
                font-weight: 400;
                font-size: 13px !important;
            }

            &.error {
                .calendarButton {
                    border: 1px solid rgb(255, 67, 67)
                }
            }
        }
    }

    .bookButton {
        cursor: pointer;

        @include enable_hover() {
            &:hover {
                background-color: $hover_theme_color;
            }
        }

        outline: none;
        border-radius: 25px;
        color: white;
        margin-top: 30px;
        padding: 10px;
        width: 100%;
        background-color: $theme_color;
        color: white;
        text-align: center;
        position: relative;

        .loadingWrapper {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%) scale(0.5);
            transition: opacity 0.3s ease-in-out;
        }

        .error {
            transition: opacity 0.3s ease-in-out;
            position: absolute;
            font-size: 13px;
            background-color: rgb(255, 67, 67);
            width: 250px;
            padding: 6px;
            left: 50%;
            top: -35px;
            transform: translateX(-50%);
            border-radius: $input_border_radius;
            display: block;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            pointer-events: none;

            @include tablet() {
                padding: 10px;
                top: -50px;
            }
        }

        &.loading {
            .loadingWrapper {
                opacity: 1;
            }

            .text {
                opacity: 0;
            }
        }

        &.loadError {
            .error {
                opacity: 1;
            }
        }

        &:not(.loadError) {
            .error {
                opacity: 0;
                transform: translateX(-50%) scale(0.6);
            }
        }

        &:not(.loading) {
            .loadingWrapper {
                opacity: 0;
            }

            .text {
                opacity: 1;
            }
        }

    }
}


.popupInput {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    position: relative;
    overflow: hidden;
    border-radius: $input_border_radius;

    button {
        position: absolute;
        padding: 1px 10px 3px 10px;
        cursor: pointer;
        color: white;
        background-color: rgba(0, 0, 0, 0.025);
        color: black;
        height: 100%;
        top: 0;

        &.minus {
            padding: 1px 12px 3px 12px;
            left: 0;
        }

        &.plus {
            right: 0;
        }

        @include enable_hover() {
            &:hover {
                background-color: rgba(0, 0, 0, 0.05);
            }
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input {
        -moz-appearance: textfield;
    }

    input {
        font-size: 13px;
        overflow: hidden;
        text-align: center;
        flex: 2;
    }
}

input:-webkit-autofill {
    background-color: rgba(255, 255, 255, 0.05) !important;
    /* Убираем фоновый цвет */
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    color: #fff !important;
    /* Сохраняем плавный переход фона */
}

/* Для Firefox */
input:-moz-autofill {
    background-color: rgba(255, 255, 255, 0.05) !important;
    color: #fff !important;
    /* Убираем фоновый цвет */
}

.error {
    input {
        border: 1px solid rgb(255, 23, 66) !important;
    }
}

.success {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 30px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(20px);
    z-index: 10000000;
    border-radius: $border_radius;
    transition: opacity 0.3s ease-in-out;

    .closeSuccess {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: $border_radius;
        padding: 10px 15px;
        color: black;
        backdrop-filter: blur(20px);

        &:hover {
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

    &:not(.visible) {
        pointer-events: none;
        opacity: 0;
    }
}

.AddingField {

    .bookTitle {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .Add {
        height: 25px;
        width: 25px;
        background-color: $theme_color;
        border-radius: 50%;
        background-image: url("../../../public/images/plus_white.svg");
        background-size: 10px;
        background-position: 50%;
        background-repeat: no-repeat;

        @include enable_hover() {
            &:hover {
                background-color: $hover_theme_color;
            }
        }
    }
}

.WrapperPickServices {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #cdcdcd77;
    backdrop-filter: blur(10px);
    top: 0;
    left: 0;
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: fadeIn 0.3s ease-out forwards;

    .Inner {
        max-width: $page_max_width;
    }

    &.OpacityWrapper {
        background-color: transparent;
        backdrop-filter: none;
    }
}

.SelectField {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.ServiceField {
    &.On {
        background-color: $input_background;
        padding: 10px;
        border-radius: $input_border_radius;
    }

    margin-bottom: 15px;

    .bookTitle {
        margin-bottom: 0px !important;
    }

    .ServicesList {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
        margin-bottom: 10px;

        .ServiceItem {
            display: flex;
            gap: 5px;
            align-items: center;
            font-size: 13px;

            .imageWrapper {
                width: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: 10px;
                }
            }

            .Delete {
                height: 15px;
                width: 15px;
                background-image: url("../../../public/images/minus_black.svg");
                background-repeat: no-repeat;
                background-size: 50%;
                background-position: 50%;
                background-color: $grey_button2;

                @include enable_hover() {
                    &:hover {
                        background-color: $hover_grey_button_color;
                    }
                }

                border-radius: 50%;
            }
        }
    }
}

.Comment {
    textarea {
        padding: 10px 10px 0px 10px;
        border-radius: $input_border_radius;
        background-color: $input_background;
        font-size: 13px;
        resize: none;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background-color: $second_color
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background-color: rgb(121, 121, 121);
        }
    }
}